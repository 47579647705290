import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { IRelatedLink, IGovInfoList } from '../../model/relatedlink';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-related-link-list',
  templateUrl: './related-link-list.component.html',
  styleUrls: ['./related-link-list.component.css', '../../../assets/CustomizationMofia/commons/page/css/am-pagination.css']
})
export class RelatedLinkListComponent implements OnInit {

  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  p: number;
  public Lang: string;
  private _title: Title;

  public relatedLinkList: any[];
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this._title = titleService;
  }

  filter: IRelatedLink = {
    name: "",
    type: 2,
    lang: Number(localStorage.getItem('Language'))
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("convenience");
    this.navSel.emit("/RelatedLinkList");

    this._http.post(this._baseUrl + 'RelatedLink/SearchRelatedLink', this.filter).subscribe((response) => {

      this.p = 1;
      this.relatedLinkList = response['data'];
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 相關連結");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Links");
    }
  }
  clickup(id: number) {

    this._http.post(this._baseUrl + 'RelatedLink/SaveRelatedLink', id).subscribe((response) => {
      if (response['codeThird'] === "0000") {
        this.relatedLinkList = response['data'];
      }
      this._http.post(this._baseUrl + 'RelatedLink/SearchRelatedLink', this.filter).subscribe((response) => {
        this.relatedLinkList = response['data'];
      });
    });

  }
}
