import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import listPlugin from '@fullcalendar/list'; // for dateClick
import '@fullcalendar/core/locales/zh-tw';
import { EventInput } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activity-workshop-main',
  templateUrl: './activity-workshop-main.component.html',
  styleUrls: [
    './activity-workshop-main.component.css',
    '../../../assets/CustomizationMofia/register/css/register.css',
  ]
})
export class ActivityWorkshopMainComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public clickDay: string = "";
  public changeTab: boolean = false;

  private _title: Title;

  public defaultOpen: string = "workshop";

  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  private calendarApi

  //日曆套件
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin, listPlugin];
  //日曆事件
  calendarEvents: EventInput[] = [];

  //日曆Header轉換繁體中文
  calendarButtonText = {
    today: '今天', month: '月', week: '週', day: '天', list: '活動列表'
  };

  //日曆Header擺放位置
  calendarHeader = {
    left: 'prev',
    center: 'title',
    right: 'next' //dayGridMonth timeGridDay
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("registration");
    this.navSel.emit("/ActivityWorkshopMain");
    this.isLangDisplay.emit(true);

    this._title.setTitle("臺中市纖維工藝博物館 - 活動研習");
  }

  openCity(evt, cityName) {

    this.clickDay = "";

    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  ClickDay(info) {
    this.clickDay = info.dateStr;

    //清空已被選擇的class
    let selectedDate = document.getElementsByClassName('selected-date')[0];
    if (selectedDate != undefined) {
      selectedDate.classList.remove("selected-date");
    }
    //將新的加入selected-date
    info.dayEl.classList.add("selected-date");
  }

}
